import React from "react"
import { graphql } from "gatsby"
import { Flex, Text } from "rebass"
import { Layout, SEO, ContactForm } from "@components"
import { CldImage } from "@extend"

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Contact"/>
      <Text as="h1" textAlign='center' mt={'60px'} mb={20}>Get in touch</Text>
      <Flex justifyContent={'space-between'} flexDirection={['column', 'row']}>
        <Flex width={[1, .5]} p={20} justifyContent='center' alignItems={'center'} >
          <ContactForm/>
        </Flex>
        <Flex width={[1, .5]} p={[0, 20]}>
          {/* <Text as='h2'>We would love to hear from you.</Text> */}
          {/* <Text as='p'>If you are interested in working together or if you have any questions or suggestions, please contact us through the form or send us an email.</Text>
          <Text as='span' fontSize={20}>contact@yabuko.com</Text> */}
          <CldImage alt="Contact" sx={{ objectFit: 'scale-down' }} src='https://res.cloudinary.com/yabuko/image/upload/v1588347959/website/20200501_172451_mdwdg9.jpg' transformations={['e_blackwhite', 'c_scale', 'w_500']}/>          
        </Flex>
      </Flex>      
    </Layout>
  )
}

export default Contact
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }    
  }
`